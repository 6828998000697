
import {defineComponent} from "vue";
import {useApplication} from "@/store/pinia/applicationStore";

export default defineComponent({
  setup(){
    return {applicationStore: useApplication()}
  },
  methods: {
    goBack(){
      this.$router.back()
    }
  },
  computed: {
    mobileHeader(): string {
      let currentRouteName = this.applicationStore.route.name;
      switch (true){
        case (currentRouteName.indexOf('general') > -1):
          return 'General Settings'
        case (currentRouteName.indexOf('logo') > -1):
          return `${this.entityType} Logo`
        case (currentRouteName.indexOf('call-types') > -1):
          return 'Manage Call Types'
        case (currentRouteName.indexOf('venues') > -1):
          return 'Manage Venues'
        case (currentRouteName.indexOf('widgets') > -1):
          return 'Manage Widgets'
        default:
          return `${this.entityType} Settings`
      }
    },
    showChevron(): boolean {
      return (this.$route.name as string).indexOf('settings') > -1
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      return this.$route.params['productionId'] as string
    },
    entityType(): string {
      return this.isOrganization ? 'Organization' : 'Production'
    }
  },
})
